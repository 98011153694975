
.remarks-container {
  max-height: 150px; /* Adjust the height as needed */
  overflow-y: auto;
  padding-right: 15px; /* Add right padding to accommodate scrollbar */
}

.remarks-table {
  width: 100%;
  table-layout: fixed;
}

.remarks-row {
  display: flex;
}

.remarks-name {
  width: 20%;
  font-weight: bold;
  padding: 5px;
  font-size: 15px;
  border-right: none !important;
}

.remarks-message {
  max-width: 80%; /* Set the maximum width of the table cell */
  word-wrap: break-word; /* Force words to wrap within the table cell */
  text-align: left;
  font-size: 15px;
  padding: 5px;
  background-color: #e0e0e0;
  border-radius: 15px !important;
}


.body_background {
  background-color: #ffffff; 
  background-size: cover;
  top: 0;
  width: 100%;
  bottom: 0;
  opacity: 1;
  z-index: 999;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  }
  
  .access_form {
    margin: 50px auto;
    max-width: 920px;
    padding: 0px 30px 30px 30px;
    border: 1px solid #CCCCCC;
    background-color: #d8d8d8;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    border-radius: 5px;
  }
  

  .access_label {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    display: inline-block; /* Set label to inline-block */
    width: 100px; /* Set label width */
    color: #3e3e3e;
  }
  
  .access_input {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 50px;
    width: 400px;
    box-sizing: border-box;
    display: block; /* Set input to block to take full width */
    font-size: 16px;
    text-align: center;
  }
  
  #access_form_main button {
    background-color: #ebbf61;
    font-size: 20px;
    font-weight: bold;
    color: #3e3e3e;
    border: none;
    padding: 10px 30px;
    margin-top: 0px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  #access_form_main button:hover {
    color: white;
    background-color: #2b2b2b;
  }


  @media screen and (max-width :900px) {
    .access_form {
      margin: 20px;
      padding: 10px;

  }
}

@media screen and (max-width :500px) {
  .access_input {
    width: 300px;
  }
}

@media screen and (max-width :400px) {
  .access_input {
    width: 200px;
  }
}
.report-modal .modal-dialog{
    max-width: 80%;
    background-color: blueviolet;
}

.report-download-csv{
    margin-left: 10px;
    background-color:#3e3e3e ! important;
    border: none ! important; /* Remove the border */
}

.toggleButton{
    margin-left: 10px;
    background-color:#3e3e3e ! important;
    border: none ! important; /* Remove the border */
}

/* ReportTable.css */
/* ... Other styles ... */

/* Styling for expand and collapse icons */
.expand-icon {
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
  }
  
  .collapse-icon {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
  }
  
  #export_select_prj_report_table{
    background-color: #3e3e3e;
    border: none;
    color: rgb(255, 255, 255);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin-bottom: 0px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }

  #export_select_prj_report_table:hover {
    background-color: #2b2b2b;
  }
  
  #export_select_prj_report_table option{
    background-color: #ffffff;
    color: rgb(0, 0, 0);
  }

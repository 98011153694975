/* Form Styles */
/*
body {
  font-family: "open sans, sans-serif";
}
*/

/*background: url(./images/img-7.jpg) top left repeat 
 */
 .login-form {
  background-color: #3e3e3e; 
  background-size: cover;
  top: 0;
  width: 100%;
  bottom: 0;
  opacity: 1;
  z-index: 999;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}
 
.issue-form {
  margin: 100px auto;
  max-width: 920px;
  padding: 50px;
  border: 1px solid #CCCCCC;
  background-color: #d8d8d8;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
  border-radius: 5px;
}


#subject_line,
.issue-form textarea{
  display: inline-block;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ffffff;
  background-color: #F8F8F8;
  font-size: 16px;
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-left: 0px;
  height: 45px;}

#subject_line{
  width:100%;
}
#ticket_category {
  display: inline-block;
  cursor: pointer;
  width: 260px;
  margin-right: 10px;
  font-family: 'Jost', sans-serif;
  margin-bottom: 10px;
}

#ticket_category1 {
  display: inline-block;
  cursor: pointer;
  width: 400px;
  margin-right: 10px;
  font-family: 'Jost', sans-serif;
  margin-bottom: 10px;
}

#hardware_type {
  display: inline-block;
  cursor: pointer;
  width: 260px;
  margin-right: 10px;
  font-family: 'Jost', sans-serif;
  margin-bottom: 10px;
}

#ticket_type {
  display: inline-block;
  cursor: pointer;
  width:269px;
  font-family: 'Jost', sans-serif;
  margin-bottom: 10px;
}

#ticket_type1 {
  display: inline-block;
  cursor: pointer;
  width: 400px;
  font-family: 'Jost', sans-serif;
  margin-bottom: 10px;
}

#helpdesk-project_name{
  display: inline-block;
  cursor: pointer;
  width: 400px;
  margin-right: 15px;
}

input[type="file"] {
  width: 350px;
  height: 35px;
}

input:last-of-type::file-selector-button {
  height: 100%;
}

.input-container {
  flex: 1;
  margin-right: 20px;
}


.issue-form textarea {
  resize: vertical;
  height: 250px;
  min-height: 100px; /* set the minimum height to 100px */
  width: 100%;
  font-family: 'Jost', sans-serif;
}

.issue-form button {
  display: inline-block;
  background-color: #ebbf61;
  font-size: 20px;
  font-weight: bold;
  color: #3e3e3e;
  border: none;
  padding: 10px 30px;
  margin-top: 0px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.issue-form button:hover {
  color: white;
  background-color: #2b2b2b;
}

.issue-form #submit {
  margin-right: 10px;
}

#loader-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #e3b03f;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}


.without::-webkit-datetime-edit-ampm-field {
  display: none;
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 1024px) {

  .issue-form {
    max-width: 800px;
  }

 #subject_line{
  width:100% ;
 }

 #ticket_category {
  width: 230px;
  margin-right: 5px;
}

#ticket_category1 {
  width: 350px;
  margin-right: 5px;
}

#hardware_type {
  width: 230px;
  margin-right: 5px;
}

#ticket_type {
  width:228px;
}

#ticket_type1 {
  width: 343px;
}

#helpdesk-project_name{
  width: 350px;
  margin-right: 7px;
}

input[type="file"] {
  width: 300px;
}

}


@media screen and (max-width: 900px) {

  .issue-form {
    max-width: 700px;
  }
   #ticket_category {
    width: 185px;
    margin-right: 15px;
  }
  
  #ticket_category1 {
    width: 300px;
    margin-right: 10px;
  }
  
  #hardware_type {
    width: 184px;
    margin-right: 15px;
  }
  
  #ticket_type {
    width:180px;
  }
  
  #ticket_type1 {
    width: 270px;
  }

  #helpdesk-project_name{
    width: 300px;
    margin-right: 10px;
  }

  input[type="file"] {
    width: 270px;
  }
}

@media screen and (max-width: 760px) {
  .issue-form {
    max-width:600px;
    padding: 40px;
  }


   #ticket_category {
    width: 100%;
  }
  
  #ticket_category1 {
    width: 100%;
  }
  
  #hardware_type {
    width: 100%;
  }
  
  #ticket_type {
    width:100%;
  }
  
  #ticket_type1 {
    width: 100%;
  }

  .issue-form textarea {
    height: 150px;

  }

  #helpdesk-project_name{
    width: 100%;
    margin-bottom: 10px;
  }

  #attachment{
    width:100%;
  }
}

@media screen and (max-width: 640px) {
  .issue-form{
    width: 400px;
   }
}

@media screen and (max-width: 540px) {
  .issue-form {
    padding: 30px;
  }

}


@media screen and (max-width: 460px) {

  .issue-form {
    max-width: 300px;
    padding: 20px;
  }


  .issue-form button {

    font-size: 16px;

    padding: 5px 15px;

  }
}

@media screen and (max-width: 340px) {

  .issue-form {
    padding: 10px;
  }

}

@media screen and (max-width: 260px) {
  .issue-form {
    padding: 10px;
  }

  #subject_line{
    width: 100%;
   }
}




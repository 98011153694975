body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f8f9fa;
  }

  .navbar {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .pdf-icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 60px;
  }

  .pdf-icon {
    cursor: pointer;
    width: 400px;
    height: auto;
    margin: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    transition: transform 0.2s ease-in-out;
  }

  .pdf-icon:hover {
    transform: scale(1.1);
  }

  button,
  .pdf-icon {
    background-color: transparent;
    color: #3e3e3e;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  button:hover {
    background-color: #3e3e3e;
    color: #f5c961;
  }

  .modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }

  #modal-content {
    background-color: #fff;
    border-radius: 5px;
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
  }

  #modal-content{
    background-color: #fff;
    border-radius: 5px;
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
  }

  .close-btn {
    cursor: pointer;
    align-self: flex-end;
    font-size: 18px;
    color: #3e3e3e;
    margin: 10px;
  }

  #pdfViewer {
    flex: 1;
    width: 100%;
    height: 100%;
    border: 0;
  }
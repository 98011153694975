
/* body {
  font-family: "open sans, sans-serif";
}

*/
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 1000;
}


.form2-helpdesk-admin {
  margin: auto;
  width: 98%;
  margin-top: 100px;
  border: none;
  background-color: #FFFFFF;
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.5);
  border-radius: 5px;
  margin-bottom: 50px;
  padding-bottom: 5px;
  position: relative; /* Add position relative */
}
.form2-helpdesk-user {
  margin: auto;
  width: 48%;
  margin-top: 100px;
  border: none;
  background-color: #FFFFFF;
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.5);
  border-radius: 5px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  position: relative; /* Add position relative */
}

.input-labeln {
  font-size: medium;
  height: 30px;
  width: 300px;
  font-family: 'Open Sans', sans-serif;
}

#email,#status{
  display: inline-block;
  width: 300px;
  height: 40px;
  font-size: 16px;
}

.email-select .status-select {
  position: relative; /* Add position relative */
  margin-top: 10px;
  width: 100%;
  font-weight:bold;
  /* Remove z-index: 0; */
}

.status-select{
  margin-left: 20px;
}


#dropdown_2{
  display: inline-block;
}


#startdaten, #enddaten {
  height: 25px;
  width: 150px;
  text-align: center;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
}


.button {
  background-color: #ebbf61;
  border: none;
  color: #3e3e3e;
  font-weight: bold;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
 
}

.button:hover {
  background-color: #2b2b2b;
  color: #FFFFFF;
}

#export_select{

  background-color: #000000;
  border: none;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin-bottom: 0px;
  border-radius: 5px;
  cursor: pointer;
  
}

#export_select:hover {
  background-color: #2b2b2b;
}

#export_select option{
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}


#email_col{
  background-color: #ffffff;

}

.form2-helpdesk-user th,td{
  padding: 0.5rem;
  text-align: left;
}

.form2-helpdesk-admin th,td{
  padding: 0.5rem;
  text-align: left;
}

.warning-note{
  text-align: center;
  margin-bottom: 50px;
}

/* Styles for the table */

.container-table {
  width: 95% !important;
  margin: 2rem auto;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: 1.2rem;
}

.container-table th,
.container-table td {
  padding: 0.5rem;
  border-right: 1px solid lightgray;
}

/*styles for tables individual columns*/

.sequentialNumber{
  max-width: 25px;
}
.date-column{
  max-width: 100px;
  text-align: left;
}

.name-column{
  text-align: left;
}
.ticketnumber-column{
  max-width: 150px;
  word-wrap: break-word; 
  text-align: left;
}
.subjectline-column{
  max-width: 150px;
  word-wrap: break-word; 
  text-align: left;
}
.status-column{
  text-align: center;
}

.actions-column{
  text-align: center;

}
.remarks-column{
  max-width: 200px; /* Set the maximum width of the table cell */
  word-wrap: break-word; /* Force words to wrap within the table cell */
  text-align: left;
}

.issuedescription-column{
  max-width: 200px; /* Set the maximum width of the table cell */
  word-wrap: break-word; /* Force words to wrap within the table cell */
  text-align: left;
}

th {
  background-color:#f4f5fa;
  color: #3e3e3e;
}

.ccontainer-table tr {
  border-bottom: 1px solid lightgray;
  background-color: #f5f5f5;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.container-table td button {
  display: block;
  margin: auto;
  background-color: #ebbf61;
  border: none;
  color: #3e3e3e;
  padding: 3px 6px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.container-table td button:hover {
  background-color: #2b2b2b;
  color: white;
}

.page {
  display: flex;
  justify-content: center;
}

.page button {
  background-color: #ebbf61;
  border: none;
  color: #3e3e3e;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
}

.page button.active {
  color: white;
  background-color: #3d4345;
}


.icon-container {
  display: flex;
}

.icon-container button {
  margin-right: 10px;
}



/* styles of loading visual */

#loader-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #e3b03f;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



/* responsive code for report page */
@media screen and (max-width: 1091px) {
  .status-select{
    margin-left: 0px;
  }

}
@media screen and (max-width: 960px) {
   
  .input-labeln {
    height: 25px;
    width: 250px;
  
  }
  
  #email,#status{
    width: 250px;
  }
  
  
  #startdaten, #enddaten {
    height: 25px;
    width: 100px;
    font-size: 13px;
  }
  

  .button {
    padding: 5x 10px;
    font-size: 13px;
  }

  #export_select{

    padding: 10px 0px;
    font-size: 13px;
  }

  .page button {
    margin-bottom: 20px;
  }
}


@media screen and (max-width: 720px) {
  /* Vertically display the table rows inside .form2 */

  .form2-helpdesk-user{
    margin: auto;
    width:70%;
    margin-top: 60px;

  }
  
  .form2-helpdesk-user tr {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .form2-helpdesk-user tr th,
  .form2-helpdesk-user tr td {
    width: 95%;
    padding: 0.5rem;
    text-align: left;
  }

  .form2-helpdesk-admin{
    margin: auto;
    width:70%;
    margin-top: 60px;

  }
  
  .form2-helpdesk-admin tr {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .form2-helpdesk-admin tr th,
  .form2-helpdesk-admin tr td {
    width: 95%;
    padding: 0.5rem;
    text-align: left;
  }
  
  
  #email,#status {
    width: 100%;
  }

  #dropdown_2{
    width: 100%;
  }

  #startdaten,
  #enddaten {
    margin-left: 0;
    margin-top: 10px;
    width:100%
  }
}

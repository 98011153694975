/* AlertModal.module.css */

.overlay-helpdesk{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 3, 3, 0.6);
  backdrop-filter: blur(3px);
  z-index: 1056;
}


.alertmodal-helpdesk{
  z-index: 1058 !important;
}
  
  



/* ProjectTrackerLandingPage.css */

.P_header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  background-color: #ffffff;
  border: 1px solid #CCCCCC;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.P_header img {
  height: 50px;
}

.P_signout-container {
  margin-right: 20px;
  text-align: end;
  justify-content: end;
  width: 81.7vw;
}

#P_signout {
  display: inline-block;
  font-size: 17px;
  padding: 0.5rem;
  color: #3e3e3e;
  border: none;
  margin-top: 0px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  position: relative; /* Add this line to change the positioning context */
}

#P_signout::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px; /* adjust the position of the underline as needed */
  width: 100%;
  height: 2px;
}

#P_signout:hover::after {
  color: #ebbf61;
  background-color: rgb(0, 0, 0); /* change the color of the underline on hover as needed */
}


.choose_application {
   margin-top: 15%;
}

#P_heading1 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 80px;
}

.P_options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 150px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.P_options li {
  color: #3e4145;
  text-decoration: none;
  font-size: 17px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.Plink {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease;
}

.Plink:hover {
  color: #000000;
}

.Picon {
  margin-bottom: 5px;
}

.Picon i {
  color: #ebbf61;
  font-size: 5rem;
  margin-bottom: 5px;
}

.Plink:hover .Picon i {
  color: #000000;
}

@media (max-width: 960px) {
  .P_header {
    height: 60px;
    font-size: 1rem;
  }

  .P_header img {
    height: 30px;
  }

  .P_options {
    gap: 100px;
  }
}
/* Media Queries */
@media (max-width: 768px) {

  .choose_application {
    margin: 50px auto;
    margin-top: 100px;
  }

  #P_heading1 {
    font-size: 24px;
    margin-bottom: 60px;
  }

  .P_options {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .P_options li {
    font-size: 14px;
  }
}
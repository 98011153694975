
.skill-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  
  .skill-item {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    padding: 4px 8px;
    margin-right: 4px;
    margin-bottom: 4px;
    border-radius: 4px;
    font-size: 16px;
    height:30px;
  }
  
  .remove-skill-button {
    font-size: 16px;
    color: #666 !important;
    padding: 0;
    margin-left: 7px;
    margin-right: 0px;
    cursor: pointer;
    text-decoration: none !important;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .skill-input {
    flex-grow: 1;
    border-radius: 4px;
    padding: 7px;
  }
  
  .add-skill-button {
    padding:0px;
    margin-left: 8px;
    height: 38px;
    width:110px;
  }
  
/*
body {
  font-family: "open sans, sans-serif"s;
}
*/

img{
  height : 125px;
}

#welcome_note{
  margin-top: 3%;
  margin-bottom: 3%;
}
#signin-button{
  font-size: medium;
  background-color: #ebbf61;
  border-radius: 5px;
  padding: 10px 30px;
  cursor: pointer;
  font-family: 'open sans', sans-serif
}

#signin-button:hover {
  color: white;
  background-color: #2b2b2b; /* change the color of the underline on hover as needed */
}

.signin {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
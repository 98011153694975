.EmpForm {
  padding: 50px;
}

.sideWidth {
  width: 200px;
}

.App {
  padding: 0px !important;
}

.sidebar {
  width: 11rem !important;
}

.cardIcon{
  font-size: 7em;  
}

.plusIcon{
  font-size: 3em;  
}

.App {
  font-family:'open sans', sans-serif;
  
}

.nav .nav-item .nav-link{
  color: #000000;
  margin-bottom: 10px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff !important;;
  background-color: #3E3E3E !important;;
}

.Header {
  padding: 0px !important;
}

.P_navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  height: 70px;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  background-color: #ffffff;
  border: 1px solid #CCCCCC;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
  margin-bottom: 20px;
  /* Add any other custom styles here */
}


#button-addon1 {
  background-color: #ebbf61;
  color: #000000;
  border-color: #d49a1c;
  /* Add any other custom styles here */
}

#button-addon1:hover {
  background-color: orange;
}

.chart-modal .modal-dialog{
  max-width: 80%;
  background-color: blueviolet;
}

.filter-div{
  margin-bottom: 10px;
}

.filter-div label{
  margin-right: 5px;
}

.legend {
  display: flex;
  justify-content:right;
  margin-bottom: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.color-box {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.non-current-member {
  color: red !important;
}

.non-current-member input[type="date"] {
  color: red !important;
}
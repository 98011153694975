/*
body {
  font-family: Arial, Helvetica, sans-serif;
}
*/

#header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  background-color: #ffffff;
  border: 1px solid #CCCCCC;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
}

#header img {
  height: 60px;
  margin-left:30px;
}

.nav-menu{
  display: grid;
  grid-template-columns: repeat(5,auto);
  grid-gap:10px;
  text-align: center;
  justify-content: end;
  width: 80vw;
  list-style: none;
  height: auto;
}

/* font-weight: bold;
text-transform: uppercase;
letter-spacing: 0.1rem;
*/
.link {
  color: #3e4145;
  text-decoration: none;
  font-size: 17px;
  padding: 0.5rem;
  margin-right: 20px;
  border-radius: 0.5rem;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px; /* adjust the position of the underline as needed */
  width: 100%;
  height: 2px;
}
.link:hover:after {
  color: #ebbf61;
  background-color: rgb(0, 0, 0); /* change the color of the underline on hover as needed */
}

.active {
  color: #ebbf61 !important; /* set the font color of active link */
  border-radius: 5px; /* add a border radius to make the link look like a button */
  padding: 8px 12px; /* add some padding to the link */
}


.menu-icon{
  display: none;
}

@media only screen and (max-width: 960px) {
  #header {
 height: auto;
  }
  #header img {
    height: 40px;
    margin-left:5px;
  }
  
  .menu-icon{
    display: block;
    position: absolute;
    top:0;
    right: 0;
    font-size: 1.8rem;
    color: black;
    transform: translate(-100%,25%);
    cursor: pointer;
  }
  
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 10vh;
    left:-100%;
    transition: all .4s ease;
    overflow: auto;
    opacity: 0;
    z-index:1000;
  }

  .nav-menu.active{
    background-color: #242222;
    left: 0;
    transition: all .5s ease;
    z-index: 1000;
    opacity: 1;
  }

  .link {
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out;
    position: relative;
  }
  
  .link::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px; /* adjust the position of the underline as needed */
    width: 100%;
    height: 2px;
  }
  .link:hover:after {
    color: #ebbf61;
    background-color: rgb(255, 255, 255); /* change the color of the underline on hover as needed */
  }
  
  .active {
    color: #ebbf61; /* set the font color of active link */
    border-radius: 5px; /* add a border radius to make the link look like a button */
    padding: 8px 12px; /* add some padding to the link */
  }
  
  


}
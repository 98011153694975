/* Form Styles */
/*
body {
  font-family: "open sans, sans-serif";
}
*/

/*background: url(./images/img-7.jpg) top left repeat 
 */
 
 .login-form {
  background-color: #3e3e3e; 
  background-size: cover;
  top: 0;
  width: 100%;
  bottom: 0;
  opacity: 1;
  z-index: 999;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}
 
.form {
  margin: 100px auto;
  padding: 50px;
  border: 1px solid #CCCCCC;
  background-color: #d8d8d8;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
  border-radius: 5px;
}


.form select,
.form input[type="date"],
.form textarea {
  display: inline-block;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ffffff;
  background-color: #F8F8F8;
  font-size: 16px;
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-left: 0px;
}

#dropdown_1 {
  display: inline-block;
  cursor: pointer;
  width: 400px;
  margin-right: 20px;
  font-family: 'Jost', sans-serif;
}

#date{
  cursor: pointer;
  width:200px;
  margin-right: 20px;
  font-family: 'Jost', sans-serif;
  text-transform: uppercase;
}

#hours{
  margin-right: 7px;
}
#hours ,#minutes {
  cursor: pointer;
  width: 100px;
  font-family: 'Jost', sans-serif;
}


.form textarea {
  resize: vertical;
  height: 250px;
  min-height: 100px; /* set the minimum height to 100px */
  width: 100%;
  margin-bottom: 20px;
  font-family: 'Jost', sans-serif;
}

.form button {
  display: inline-block;
  background-color: #ebbf61;
  font-size: 20px;
  font-weight: bold;
  color: #3e3e3e;
  border: none;
  padding: 10px 30px;
  margin-top: 0px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.form button:hover {
  color: white;
  background-color: #2b2b2b;
}

.form #submit {
  margin-right: 10px;
}

#loader-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #e3b03f;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}


.without::-webkit-datetime-edit-ampm-field {
  display: none;
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 1024px) {
  .form {
    max-width: 800px;
  }
  #dropdown_1 {
    width: 250px;
  }

  #date{
    width: 135px;
  }
  #hours,#minutes {
    width: 75px;
  }

  #Task-Description-label{
    margin-left: 0px;
  }
}


@media screen and (max-width: 900px) {
  .form {
    margin: 20px auto;
    padding: 30px;
  }
  .form select,
  .form input[type="date"],
  .form textarea {
    width: 100%;
  }
  
  #dropdown_1  {
    margin-bottom: 10px;
    width: 100%;
 
  }

  #date{
    width:100%;
  }
  
  #hours,#minutes {
    width: 100%;

  }
  #Task-Description-label{
    margin-left: 0px;
  }
}

@media screen and (max-width: 760px) {
  .form {
    max-width: 400px;
  }
  .form select,
  .form input[type="date"],
  .form textarea {

    width: 100%;
    margin-bottom: 10px;
  }
  #dropdown_1 {
    width: 100%;
  }

  #date{
    width:100%;
  }

  #hours,#minutes {
    width: 100%;

  }

  #Task-Description-label{
    margin-left: 0px;
  }
}

@media screen and (max-width: 640px) {
  .form {
    margin: 20px;
    padding: 20px;
  }
  .form select,
  .form input[type="date"],
  .form textarea {

    width: 100%;
    margin-bottom: 10px;
  }
  #dropdown_1 {
    width: 100%;
  }

  #date{
    width:100%;
  }

  #hours,#minutes {
    width: 100%;

  }

  #Task-Description-label{
    margin-left: 0px;
  }
}

@media screen and (max-width: 540px) {
  .form {
    max-width: 300px;
    margin: 20px;
    padding: 20px;
  }
  .form select,
  .form input[type="date"],
  .form textarea {

    width: 100%;
    margin-bottom: 10px;
  }
  #dropdown_1 {
    width: 100%;
  }

  #date{
    width:100%;
  }

  #hours,#minutes {
    width: 100%;

  }

  #Task-Description-label{
    margin-left: 0px;
  }
}


@media screen and (max-width: 360px) {
  .form button {

    font-size: 16px;

    padding: 5px 15px;

  }
}

@media screen and (max-width: 230px) {
  .form {
    padding: 10px;
  }
}




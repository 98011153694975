
/* body {
  font-family: "open sans, sans-serif";
}

*/

.tasksheet-edit-modal .modal-dialog{
  max-width: 60%;
  background-color: blueviolet;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 1000;
}

.form2 {
  margin: auto;
  width: 98%;
  margin-top: 100px;
  border: none;
  background-color: #FFFFFF;
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.5);
  border-radius: 5px;
  margin-bottom: 50px;
  padding-bottom: 5px;
  position: relative; /* Add position relative */
}

.input-labeln {
  font-size: medium;
  height: 30px;
  width: 300px;
  font-family: 'Open Sans', sans-serif;
}

#email{
  display: inline-block;
  width: 300px;
  height: 40px;
  font-size: 16px;
}

.email-select {
  position: relative; /* Add position relative */
  margin-top: 10px;
  width: 100%;
  font-weight:bold;
  /* Remove z-index: 0; */
}

#dropdown_2{
  display: inline-block;
}


#startdaten, #enddaten {
  height: 25px;
  width: 150px;
  text-align: center;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
}


.button {
  background-color: #ebbf61;
  border: none;
  color: #3e3e3e;
  font-weight: bold;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
 
}

.button:hover {
  background-color: #2b2b2b;
  color: #FFFFFF;
}

#export_select{

  background-color: #000000;
  border: none;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin-bottom: 0px;
  border-radius: 5px;
  cursor: pointer;
  
}

#export_select:hover {
  background-color: #2b2b2b;
}

#export_select option{
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}


#email_col{
  background-color: #ffffff;

}

.form2 th,td{
  padding: 0.5rem;
  text-align: left;
}



/* Styles for the table */

.container {
  width: 95%;
  margin: 2rem auto;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: 1.2rem;
}

.container th,
.container td {
  padding: 0.5rem;
  border-right: 1px solid lightgray;
}

/*styles for tables individual columns*/

.date-column{
  max-width: 100px;
  text-align: left;
}

.name-column{
  text-align: left;
}
.projectname-column{
  max-width: 150px;
  word-wrap: break-word; 
  text-align: left;
}
.timespent-column{
  text-align: center;
  max-width:50px ;
  
}

.green {
  color: green;
}

.orange {
  color: orange;
}

.red {
  color: red;
}

.taskdescription-column{
  max-width: 200px; /* Set the maximum width of the table cell */
  word-wrap: break-word; /* Force words to wrap within the table cell */
  text-align: left;
}

th {
  background-color:#f4f5fa;
  color: #3e3e3e;
}

.container tr {
  border-bottom: 1px solid lightgray;
  background-color: #f5f5f5;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.container td button {
  display: block;
  margin: auto;
  background-color: #ebbf61;
  border: none;
  color: #3e3e3e;
  padding: 3px 6px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.container td button:hover {
  background-color: #2b2b2b;
  color: white;
}

.page {
  display: flex;
  justify-content: center;
}

.page button {
  background-color: #ebbf61;
  border: none;
  color: #3e3e3e;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
}

.page button.active {
  color: white;
  background-color: #3d4345;
}


/*styles for the edit pop up modal*/

.custom-modal {
  margin: 100px auto;
  max-width: 870px;
  padding: 0px 50px 50px 50px;
  border: 1px solid #CCCCCC;
  background-color: #d8d8d8;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
  border-radius: 5px;
  max-height: 80vh;
  overflow: auto;
  z-index: 3;
}

.editform select,
.editform input[type="date"],
.editform textarea {
  display: inline-block;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ffffff;
  background-color: #F8F8F8;
  font-size: 16px;
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-left: 0px;
}

#dropdown_1_editform {
  display: inline-block;
  cursor: pointer;
  width: 400px;
  margin-right: 20px;
  font-family: 'Jost', sans-serif;
}

#date_editform{
  cursor: pointer;
  width:200px;
  margin-right: 20px;
  font-family: 'Jost', sans-serif;
  text-transform: uppercase;
}

#hours_editform{
  margin-right: 10px;
}

#hours_editform ,#minutes_editform{
  cursor: pointer;
  width: 100px;
  font-family: 'Jost', sans-serif;
}

#taskdescription_editform {
  resize: vertical;
  height: 250px;
  min-height: 100px; /* set the minimum height to 100px */
  width: 100%;
  margin-bottom: 20px;
  font-family: 'Jost', sans-serif;
}

.editform button {
  display: inline-block;
  background-color: #ebbf61;
  font-size: 20px;
  font-weight: bold;
  color: #3e3e3e;
  border: none;
  padding: 10px 30px;
  margin-top: 0px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-right: 20px;
}

.editform button:hover {
  background-color: #2b2b2b;
  color: white;
}

.editform #submit {
  margin-right: 10px;
}



/* styles of loading visual */

#loader-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #e3b03f;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



/* responsive code for edit form */
@media screen and (max-width: 1044px) {
.tasksheet-edit-modal .modal-dialog{
  max-width: 80%;
  background-color: blueviolet;
}
}

@media screen and (max-width: 1024px) {
  .custom-modal {
    max-width: 700px;
  }
  #dropdown_1_editform {
    width: 325px;
  }

  #date_editform{
    width: 145px;
  }
  #hours_editform,#minutes_editform {
    width: 80px;
  }

  #Task-Description-label{
    margin-left: 0px;
  }
}


@media screen and (max-width: 900px) {

  .custom-modal{
    max-width: 400px;
    margin:50px auto;
    
  }

  .editform select,
  .editform input[type="date"],
  .editform textarea {
    width: 100%;
  }
  
  #dropdown_1_editform  {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }

  #date_editform{
    width:100%;
    height: 40px;
  }
  
  #hours_editform,#minutes_editform {
    width: 100%;
    height: 40px;
  }
  #Task-Description-label{
    margin-left: 0px;
  }
  #taskdescription_editform{
    height: 200px;
  }
}

  
@media screen and (max-width: 640px) {

  .custom-modal{
    max-width: 300px;
    padding: 0px 10px 10px 10px;
  }

  .editform select,
  .editform input[type="date"],
  .editform textarea {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  #dropdown_1_editform {
    width: 100%;
  }

  #date_editform{
    width:100%;
  }

  #hours_editform,#minutes_editform {
    width: 100%;

  }

  #Task-Description-label{
    margin-left: 0px;
  }
   
  .editform button {

    padding: 5px 20px;
  
  }
  
}

@media screen and (max-width:520px) {

  .custom-modal{
    max-width: 250px;
  }

  .editform select,
  .editform input[type="date"],
  .editform textarea {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  #dropdown_1_editform {
    width: 100%;
  }

  #date_editform{
    width:100%;
  }

  #hours_editform {
    width: 100%;

  }

  #Task-Description-label{
    margin-left: 0px;
  }
}

/* responsive code for report page */
@media screen and (max-width: 960px) {
   
  .input-labeln {
    height: 25px;
    width: 250px;
  
  }
  
  #email{
    width: 250px;
  }
  
  
  #startdaten, #enddaten {
    height: 25px;
    width: 100px;
    font-size: 13px;
  }
  

  .button {
    padding: 5x 10px;
    font-size: 13px;
  }

  #export_select{

    padding: 10px 0px;
    font-size: 13px;
  }

  .page button {
    margin-bottom: 20px;
  }
}


@media screen and (max-width: 720px) {
  /* Vertically display the table rows inside .form2 */

  .form2{
    margin: auto;
    width:70%;
    margin-top: 60px;

  }
  
  .form2 tr {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .form2 tr th,
  .form2 tr td {
    width: 95%;
    padding: 0.5rem;
    text-align: left;
  }
  
  #email {
    width: 100%;
  }

  #dropdown_2{
    width: 100%;
  }

  #startdaten,
  #enddaten {
    margin-left: 0;
    margin-top: 10px;
    width:100%
  }
}

/* AlertModal.module.css */

.overlay-tasksheet{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 3, 3, 0.6);
  backdrop-filter: blur(3px);
  z-index: 1056;
}


.alertmodal-tasksheet{
  z-index: 1058 !important;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
  }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }
  
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  
  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0rem 0rem 0rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    background-color: #edc46e;
  }
  
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }
  
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }
  
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }
  
  .modal-footer > :not(:first-child) {
    margin-left: 0.25rem;
  }
  
  .modal-footer > :not(:last-child) {
    margin-right: 0.25rem;
  }
  
  .modal-header .close {
    padding: 0.25rem;

    color: #000;
    cursor: pointer;
  }
  
  .modal-header .close:hover {
    color: #000;
    text-decoration: none;
  }
  
  .modal-header .close:focus {
    color: #000;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }
  
  .modal-dialog {
    max-width: 500px;
    width: 90%;
    margin: 1.75rem auto;
  }
  
  
  


